import React from "react";
import styles from "./n5-not-found.module.scss";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import logo from "../../../assets/image/n5_logo_transparent.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { scrollTo } from "../../../utils/scrollTo";

const NotFoundWrapper = ({ title, description }) => {  
  const { t } = useTranslation();
  return (
    <>
      <motion.figure
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 20 }}
        className={styles.logo}
      >        
        <img
          src={logo}
          style={{
            maxWidth: 426,
            width: "100%",
          }}
          alt="N5 Logo"
          loading="lazy"
        />
      </motion.figure>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 20, delay: 0.4 }}
        className={styles.wrapper}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", stiffness: 20, delay: 0.4 }}
          className={styles.card}
        >
          <motion.h4
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 20, delay: 0.4 }}
            className={styles.title}
          >
            {title}
          </motion.h4>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 20, delay: 0.5 }}
            className={styles.description}
          >
            {description}
          </motion.p>
          <ul className={styles.navList}>
            <li>
              <button
               onClick={() => {
                scrollTo("/", "navbar", 100);
              }}
              >
                {t("NOT_FOUND.MENU.HOME")}
              </button>
            </li>
            <li>
              <button
               onClick={() => {
                scrollTo("/platform", "navbar", 100);
              }}
              >
                {t("NOT_FOUND.MENU.PLATFORM")}
              </button>
            </li>
            <li>
              <button
               onClick={() => {
                scrollTo("/services", "navbar", 100);
              }}
              >
                {t('NOT_FOUND.MENU.SERVICES')}
              </button>
            </li>
            <li>
              <button
               onClick={() => {
                scrollTo("/content/", "navbar", 100);
              }}
              >
                {t("NOT_FOUND.MENU.CONTENT")}
              </button>
            </li>
            <li>
              <button
               onClick={() => {
                scrollTo("/work", "navbar", 100);
              }}
              >
                {t("NOT_FOUND.MENU.WORK")}
              </button>
            </li>
          </ul>
        </motion.div>
      </motion.div>
    </>
  );
};

NotFoundWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default NotFoundWrapper;
