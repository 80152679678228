/**
 * Smooth scrolling onClick event handler
 * @param {string} to page you want to go
 * @param {string} selector argument will be passed to `querySelector`, usually an HTML id
 * @param {number} [offset=45] argument will be used to determine position where will be scrolled to
 * @param {'auto' | 'smooth' } [behavior='auto'] behavior of the scroll
 */
import { navigate } from "gatsby";
import { isBrowser } from "./isBrowser";

export function scrollTo(to, selector, offset = 45, behavior = "auto") {
  navigate(to);

  setTimeout(() => {
    if (isBrowser) {
      const element = document.getElementById(selector);      
      const headerOffset = offset;
      const elementPosition = element?.getBoundingClientRect().top;      
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition - 64,
        behavior,
      });
    }
  }, 500);
}
