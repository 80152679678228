import React from "react";
import "@styles/index.scss";
import styles from "@organism/OurTeam/our-team.module.scss";
import NotFoundWrapper from "@molecules/NotFound";
import SEO from "../components/Seo";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

const NotFoundPage = ({ location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  
  return (
    <>
      <SEO
        title={t('SEO.NOT_FOUND.TITLE')}
        description={t('SEO.NOT_FOUND.DESCRIPTION')}
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      />
      <section className={`${styles.section} text-center`}>
        <NotFoundWrapper
          title={t('NOT_FOUND.TITLE')}
          description={t('NOT_FOUND.DESCRIPTION')}
        />        
      </section>
    </>
  )
}

export default NotFoundPage;
